html,
body {
  font-size: 14px !important;
  /* font-weight: 500;
  font-family: -apple-system, "Nunito", BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important; */
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
}

/*Scrollbar CSS*/
::-webkit-scrollbar {
  width: 8px;
  height: 7px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
  /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:vertical:active {
  /*background: rgba(0,0,0,0.61); /* Some darker color when you click it*/
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px;
  /*Prevent it from getting too small */
}

::-webkit-scrollbar-thumb:horizontal:active {
  /*background: rgba(0,0,0,0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
  border-radius: 100px;
}

/*------------END-----------*/

.fw {
  width: 100%;
}

.h-full {
  height: 100%;
}

.fh {
  height: 100vh;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.mr5 {
  margin-right: 5px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr40 {
  margin-right: 40px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.p5 {
  padding: 5px;
}

.p10 {
  padding: 10px;
}

.p15 {
  padding: 15px;
}

.p20 {
  padding: 20px;
}

.pr5 {
  padding-right: 5px;
}

.pr10 {
  padding-right: 10px;
}

.pr15 {
  padding-right: 15px;
}

.pr20 {
  padding-right: 20px;
}

.pl5 {
  padding-left: 5px;
}

.pl10 {
  padding-left: 10px;
}

.pl15 {
  padding-left: 15px;
}

.pl20 {
  padding-left: 20px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.nm {
  margin: 0 !important;
}

.np {
  padding: 0 !important;
}

.error {
  font-size: 12px;
  color: #f53232;
}

.pointer {
  cursor: pointer;
}

.label {
  color: #333;
  font-weight: 700;
  text-transform: capitalize;
}

.section {
  height: 35px;
  background-color: #eeedfd;
  font-size: 15px;
  font-weight: 500;
  border-radius: 4px;
}

.collapse-section {
  background-color: #eeedfd;
  border-radius: 4px;

  font-size: 15px;
  font-weight: 500;
}

.custom-input-editor {
  .ql-container {
    height: 400px;
    overflow-y: hidden;
    resize: none;
  }
}

.custom-select-icon {
  width: 100%;
  padding: 6.5px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;

  .icon-img {
    height: 2rem;
    width: 2rem;
    object-fit: contain;
  }
}

.select-icon-option {
  .icon-img {
    height: 2rem;
    width: 100%;
    object-fit: contain;
  }

  .icon-name {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// .custom-input.ant-input,
// .custom-input .ant-input,
// .custom-input > input[type="text"],
// .custom-input input,
// .custom-input.ant-picker,
// textarea.custom-input {
//   // background: #f8fafb !important;
//   // border-color: transparent !important;
//   // border: 2px solid #f3f3f3 !important;
//   font-size: 14px !important;
//   padding: 6px 16px;
//   box-shadow: none;
//   border-radius: 4px;
// }

// .custom-input {
//   .ant-input-group {
//     .ant-input-group-addon {
//       border-color: transparent !important;
//     }
//   }
// }
.ant-input-affix-wrapper {
  // border-color: transparent !important;
  background: #fff !important;
}

// .custom-input.ant-input-affix-wrapper {
//   // background: #f8fafb !important;
//   // border-color: transparent;
//   box-shadow: none;
//   border-radius: 4px;
// }
// .custom-select > .ant-select-selector {
//   // background-color: #f8fafb !important;
//   border-color: transparent !important;
//   border-radius: 4px !important;
//   font-size: 14px;
//   box-shadow: none !important;
// }
// .custom-input.white .ant-input,
// .custom-input.white {
//   background-color: #fff !important;
// }
.ant-modal-header {
  background: #f8fafb !important;
  border-bottom: 0 !important;
  padding: 15px 20px;

  .ant-modal-title {
    font-weight: 600;
  }
}

.ant-modal-body {
  padding: 15px;
}

.ant-btn-default,
.ant-btn-danger,
.ant-btn-primary,
.ant-btn-primary-green,
.ant-btn-primary-outline {
  // padding: 0 30px;
}

.ant-btn-primary {
  background-color: black !important;
  border-color: black !important;
  color: #fff !important;
}

.ant-btn-primary-green,
.ant-btn-primary-green:hover {
  background-color: #1c823e !important;
  border-color: #1c823e !important;
  color: #fff;
}

.ant-btn-primary-outline {
  border-color: #9e9998 !important;
  color: #9e9998 !important;
}

.ant-btn {
  text-transform: uppercase;
}

button[display="none"] {
  display: none;
}

.ant-image-img.cover {
  object-fit: cover;
}

.custom-upload-list {
  .ant-upload-list {
    display: flex;
    flex-flow: row wrap;
  }

  .ant-upload-list-picture-container {
    width: 350px;
    margin-right: 0.5rem;
  }

  .mUploadItem {
    position: relative;
    padding: 8px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

    .thumb-img {
      object-fit: cover;
    }

    .file-icon {
      height: 40px;
    }

    .input-container {
      flex-flow: nowrap;
    }
  }

  .custom-upload-image {
    width: 350px;
    height: 54px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
  }
}

.page_heading {
  display: flex;
  flex-flow: row wrap;
  color: #000;
  font-weight: 700;
  font-size: 20px;
  margin: 0px;
  line-height: normal;
}

.ant-pagination {
  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border: 0;
    }
  }

  .ant-pagination-prev:hover,
  .ant-pagination-next:hover {
    .ant-pagination-item-link {
      color: #333;
    }
  }

  .ant-pagination-item {
    border-radius: 5px;
    border-color: transparent;
  }

  .ant-pagination-item:hover,
  .ant-pagination-item-active {
    background-color: #333;
    border-color: #333;

    a {
      color: #fff;
    }
  }
}

.text-white {
  color: #fff;
}

.booking-history-drawer {
  .ant-drawer-body {
    padding: 16px 16px;
  }

  .ant-ribbon {
    top: -8px;
  }

  .booking-history-card {
    .ant-card-body {
      padding: 16px 16px;
    }
  }
}
