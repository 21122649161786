.nw_f_m_c {
    background: #fff !important;
    padding: 50px 0px 0px !important;
    font-size: 1.4rem;
    color: rgba(58, 59, 57, .6);

    .nw_f_l_c {
        position: relative;
    }

    .nw_f_lr_space {
        padding-left: 60px;
        padding-right: 60px;
    }

    .nw_f_i_l_c {
        position: relative;

        .nw_logo {
            width: 200px;
            height: 113px;
            margin: 0;
            display: block;
            background: url(../images/footer-logo.png) no-repeat center;
            background-size: contain;
            margin-bottom: 15px;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 1.4rem;
            color: rgba(58, 59, 57, .8);
        }

        h6 {
            font-style: normal;
            font-weight: 500;
            font-size: 1.8rem;
            color: rgba(58, 59, 57, .8);
            margin: 0;
            padding: 0;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: block;
                margin-bottom: 15px;

                a {
                    color: rgba(58, 59, 57, .8);
                    display: inline-block;
                    font-size: 1.4rem;
                    font-weight: normal;
                    text-transform: uppercase;
                }
            }
        }

        .nw_f_loc {
            li {
                position: relative;
                padding-left: 30px;

                a {
                    text-transform: initial;
                }
            }

            ::before {
                position: absolute;
                width: 20px;
                height: 20px;
                left: 0px;
                top: 50%;
                margin-top: -10px;
                content: "";
            }

            :nth-child(1) {
                ::before {
                    background: url(../images/location-icon.svg) no-repeat center;
                    background-size: contain;
                    margin-top: -18px;
                }
            }

            :nth-child(2) {
                ::before {
                    background: url(../images/tel-icon.svg) no-repeat center;
                    background-size: contain;
                }
            }

            :nth-child(3) {
                ::before {
                    background: url(../images/mail-icon.svg) no-repeat center;
                    background-size: contain;
                }
            }

            span {
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                color: rgba(58, 59, 57, .8);
                display: block;
            }
        }
    }


    .nw_c_s_c {
        position: relative;
        margin-top: 20px;
        padding: 15px 0;
        border-top: 1px solid rgba(160, 160, 160, .6);
        display: flex;
        justify-content: space-between;

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 1.2rem;
            color: rgba(58, 59, 57, .6);
            margin: 0;
            padding: 0;

        }

        .nw_c_s_b {
            position: relative;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;

                    a {
                        display: block;
                        margin-left: 10px;

                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }


}