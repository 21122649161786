$primary: black;
$activeMenuBg: #c9c8c9;

.cockpit_web {
  .nw_loc_con .nw_i_c h1 {
    font-size: 44px;
  }

  .nw_loc_con .nw_i_c p {
    font-size: 16px;
  }

  input,
  button,
  select,
  optgroup,
  textarea {
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
    }
  }

  .nw_f_m_c .nw_f_i_l_c p,
  .nw_f_m_c .nw_f_i_l_c ul li a,
  .nw_f_m_c .nw_f_i_l_c .nw_f_loc span {
    font-size: 14px;
  }

  .nw_f_m_c .nw_f_i_l_c h6 {
    font-size: 18px;
  }

  .nw_f_m_c .nw_c_s_c p {
    font-size: 12px;
  }

  .rightContainer .ant-btn,
  .rightContainer .login a {
    font-size: 14px !important;
  }

  .rightContainer .login a {
    cursor: pointer;
  }

  .ant-menu {
    font-size: 14px !important;
  }

  .nw_loc_con .nw_l_s_c .nw_l_s_i_c span .ant-select {
    font-size: 16px;
  }

  .headind-1 {
    font-size: 36px !important;
  }

  .headind-2 {
    font-size: 25px !important;
  }

  .leftpanelContainer .btn_s_c .ant-btn,
  .leftpanelContainer .btn_s_c .ant-btn {
    height: 40px !important;
    min-width: 110px !important;
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
  }

  .leftpanelContainer .d_c_b {
    max-width: 450px;
    border-radius: 5px;
  }

  .rightContainer .ant-btn,
  .btnContainer .ant-btn-primary,
  .locationContainer,
  .nw_loc_con .nw_l_s_c .nw_l_s_i_c span .ant-select,
  .nw_loc_con .btn_l_c,
  .nw_loc_con .btn_l_c:hover,
  .nw_loc_con .btn_l_c:active,
  .nw_loc_con .btn_l_c:focus {
    border-radius: 5px !important;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    -ms-border-radius: 5px !important;
    -o-border-radius: 5px !important;
  }

  .new_contain {
    object-fit: contain;
  }

  .boxContainer {
    button.ant-btn.ant-btn-primary {
      line-height: 1;
    }
  }

  .popup_main .popup_heading {
    font-size: 20px !important;
  }
}

.mainHeader {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff !important;
  padding: 0 !important;
  line-height: 1.57 !important;
  border-bottom: 1px solid #9E9998;

  .user-badge {
    background: #2469f1 !important;
    color: #ffffff !important;
    border-radius: 50px !important;
    border: 0 !important;
    font-size: 11px !important;
    padding: 5px 10px !important;
    line-height: 0.9 !important;
    border-radius: 3px !important;
    margin-top: 4px;
    margin-bottom: -2px;
  }

  .container {
    height: 64px;

    .btn-drawer-toggle {
      display: none;
    }

    .btn-book-now {
      // padding: 0 40px;
    }

    .user-info-container {
      .user-avatar {
        background: #000;
        color: #fff;
      }

      .user-info {
        min-width: 100px;
        max-width: 150px;

        .name {
          color: #333333;
          font-weight: 700;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .role {
          font-size: 12px;
          color: #9e9998;
          font-weight: 400;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.ant-dropdown-menu {
  .ant-dropdown-menu-title-content {
    .anticon {
      margin-right: 7px;
      transform: translate(0, 1px);
    }
  }
}

.custom-card {
  margin-bottom: 15px !important;

  .mt15 {
    margin-top: 13px !important;
  }
}

thead>tr>th {
  border-bottom-width: 0 !important;
  background: #f3f3f3 !important;
  border-color: #dfdfdf !important;
  font-weight: 800 !important;
}

tbody {
  >tr:nth-child(even) {
    background: #f9f9f9 !important;
  }
}

table {
  font-weight: 400 !important;
  border: 1px solid #f3f3f3;
  border-bottom: 0;

  thead {
    font-weight: 500 !important;
  }
}

.ant-table-tbody>tr>td {
  border-bottom: none;
  background-color: #ffffff;
}

.mainDashTable {
  table {
    border: 0;
  }
}

.new_image-fluid {
  object-fit: contain !important;
}

span.anticon.anticon-edit {
  font-size: 14px !important;
}

.mainMember .ant-input-affix-wrapper>input.ant-input {
  padding: 0 !important;
}

.mainMember-content {
  background: #fff;
  border-radius: 5px;
  margin-top: 15px;
  padding: 50px 20px 20px;
  height: 73vh;
  overflow-y: scroll;
}

.ant-card {
  font-weight: 400 !important;

  p {
    font-weight: 400 !important;
  }
}

.ant-card-head {
  font-weight: 600 !important;
  font-size: 18px !important;
  padding: 0 15px !important;
}

.resourceMain .ant-card-body {
  padding: 15px !important;
}

.ant-ribbon-text {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 15px;
}

.app-sidebar {
  background: black !important;
  border-radius: 0 20px 20px 0;
  /* overflow: hidden; */
  overflow-x: auto;

  .logo-container {
    height: 64px;
  }

  .ant-menu-dark {
    background: black !important;
  }

  .ant-layout-sider-children {
    .ant-menu {
      background: black !important;

      .ant-menu-item-selected {
        background: $activeMenuBg !important;

        .anticon {
          color: black;
        }

        a {
          color: black !important;
        }
      }
    }

    .ant-menu-vertical .ant-menu-item::after,
    .ant-menu-vertical-left .ant-menu-item::after,
    .ant-menu-vertical-right .ant-menu-item::after,
    .ant-menu-inline .ant-menu-item::after {
      border: 0;
    }
  }
}

.app-drawer {

  .ant-drawer-content,
  .ant-drawer-header {
    background: #000;
    border: 0;
  }

  .ant-drawer-close {
    color: #fff;
  }

  .ant-drawer-body {
    padding: 0;
  }
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: $primary !important;

  .resource-badge-count {
    margin-right: 5px;

    .ant-badge-count {
      background: $primary;
    }
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $activeMenuBg !important;

  a {
    color: $primary !important;
  }

  .resource-badge-count {
    margin-right: 5px;

    .ant-badge-count {
      background: $primary;
    }
  }
}

@media only screen and (max-width:767px) {
  .mainHeader {
    background: #000 !important;
    color: #fff;

    .container {
      color: #fff;

      .btn-book-now-container {
        display: none;
      }



      .trigger,
      .user-info-container .user-info .role,
      .user-info-container .user-info .name {
        color: #fff;
      }

      .btn-slider-toggle {
        display: none;
      }

      .btn-drawer-toggle {
        display: inline-block;
      }
    }
  }

  .app-sidebar {
    display: none;
  }
}



@import 'new_responsive_overrirde';