.--stripe-credit-card-form-container {
  .card-number-element {
    border: 1px solid #d9d9d9;
    font-size: 14px;
    padding: 6px 11px;
  }

  .card-number-element.StripeElement--focus {
    border-color: #40a9ff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .card-number-element.StripeElement--invalid {
    border-color: #ed4758;
    box-shadow: 0 0 0 2px rgba(237, 71, 88, 0.2);
  }
}
