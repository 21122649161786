.img_con {
    position: relative;

    img {
        max-width: 100%;
        width: 100%;
    }
}

.nw_c_c {
    display: flex;
    align-items: center;
    margin-top: 20px;


    .ant-carousel {
        .slick-dots-bottom {
            bottom: 5px;
        }


        .slick-dots {
            justify-content: flex-end;
            margin-right: 30px;
            margin-left: 0;
            padding-left: 0;
            list-style: none;
            text-align: right;

            li {
                width: 42px;
                background: #fff;
                height: 4px;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;

                &.slick-active {
                    width: 42px;
                    background: #74BA59;

                    button {
                        background: #74BA59;
                    }
                }
            }


        }

    }
}

.nw_loc_con {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    width: 40%;
    left: 0px;

    .nw_loc_inner_con {
        background: #fff;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding: 35px;

    }

    .nw_i_c {

        h1 {
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-size: 4.4rem;
            margin: 0;
            padding: 0;
            line-height: normal;
            font-weight: 700;
            color: #3A3B39;
            margin-bottom: 20px;

            span {
                color: #74BA59;
            }
        }

        p {
            color: rgba(58, 59, 57, .8);
            font-style: normal;
            font-weight: normal;
            font-size: 1.6rem;
            margin-bottom: 20px;
        }
    }

    .btn_l_c,
    .btn_l_c:hover,
    .btn_l_c:active,
    .btn_l_c:focus {
        background: #74BA59;
        color: #fff;
        text-transform: uppercase;
        border: none;
        height: 54px;
        border-radius: 0px !important;
        -webkit-border-radius: 0px !important;
        -moz-border-radius: 0px !important;
        -ms-border-radius: 0px !important;
        -o-border-radius: 0px !important;
    }



    .nw_l_s_c {
        position: relative;

        .nw_l_s_i_c {
            position: relative;

            span {
                display: block;

                .ant-select {
                    display: block;
                    height: 54px;
                    background: #F4F4F4;
                    display: flex;
                    align-items: center;
                    border: 0px;
                    color: rgba(58, 59, 57, .6);
                    font-size: 1.6rem;
                    font-weight: normal;
                    margin-bottom: 20px;

                    .ant-select-selector {
                        background: #F4F4F4;
                        border: 0px;
                        outline: none;
                        box-shadow: none;
                        padding: 0 30px;

                        &:before {
                            background: url(../images/location.svg) no-repeat;
                            content: "";
                            position: absolute;
                            top: 50%;
                            width: 17px;
                            height: 17px;
                            left: 10px;
                            margin-top: -7px;
                            background-size: contain;

                        }



                    }


                    .ant-select-selection-search {
                        position: absolute;
                        top: 0;
                        right: 30px;
                        bottom: 0;
                        left: 30px;

                        &:after {
                            background: url(../images/downarrow.svg) no-repeat center;
                            content: "";
                            position: absolute;
                            top: 50%;
                            width: 12px;
                            height: 12px;
                            right: -15px;
                            margin-top: -6px;
                            background-size: contain;

                        }
                    }
                }
            }

        }
    }





}


@media(max-width:991px) {

    .cockpit_web .container {
        max-width: 100%;
    }

    .nw_loc_con {
        width: 60%;
    }
}


@media(max-width:767px) {

    .nw_loc_con {
        width: 100%;
        position: relative;
        border: 1px solid #f4f4f4;
        border-top: 0px;
    }

    .nw_loc_con .nw_loc_inner_con {
        padding: 35px 15px;
    }

    .cockpit_web .nw_loc_con .nw_i_c h1 {
        font-size: 28px;
    }

    .nw_c_c {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        margin-top: 20px;
        margin-bottom: 50px;
    }
}