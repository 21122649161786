.auth-container {
  height: 100vh;

  background: url("../images/auth-bg-img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.--container {
  height: 100vh;
  padding: 24px;
  overflow: auto;
  background: linear-gradient(0deg,
      rgba(11, 18, 26, 0.7),
      rgba(11, 18, 26, 0.7)),
    linear-gradient(184.06deg,
      rgba(0, 0, 0, 0) 3.31%,
      rgba(0, 0, 0, 0.2) 46.6%,
      rgba(0, 0, 0, 0.21) 96.69%);

  .header-container {
    margin-bottom: 60px;

    h1.ant-typography {
      font-weight: 700;
    }
  }

  .logo-container {
    .logo {
      width: 200px;
    }
  }

  .label,
  .btn-link,
  .ant-typography {
    color: #fbfafb;
  }

  .label {
    font-size: 16px;
    font-weight: 500;
  }

  .form-container {
    width: 400px;

    .create-account-link {
      h2 {
        font-weight: 700;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width:767px) {
  .auth-container {
    height: 100vh;

    background: url("../images/auth-bg-img-mobile.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .--container {
      padding: 14px;

      .logo-container {
        .logo {
          width: 150px;
          margin-bottom: 0px;
        }
      }

      .form-container {
        width: 345px;
      }
    }

    h1.ant-typography {
      font-size: 24px;
    }
  }


  .btn_r_l_cb {
    font-size: 14px;
    margin-top: 15px;
  }

  .content-container {
    .mb20 {
      margin-bottom: 0px;
    }

  }
}