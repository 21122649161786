.payment-modal-container {
  width: 700px !important;

  .ant-modal-body {
    padding: 0;

    .content-container {
      max-height: 65vh;
      overflow-y: auto;
      overflow-x: hidden;

      .credit-cards-group {
        padding: 15px;
      }

      .empty-card-container {
        height: 175px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }

    .sticky-footer {
      background-color: #fff;

      box-shadow: 0 -1.5em 2.5em -0.5em rgba(#333333, 0.2);

      position: sticky;
      bottom: 0;
      z-index: 1;
    }
  }
}

@media only screen and (max-width: 576px) {
  .payment-modal-container {
    width: 360px !important;
  }
}
