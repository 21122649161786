body {

    .leftpanelContainer .btn_s_c .ant-btn {
        font-size: 14px;
        font-weight: 700;
    }

    .leftpanelContainer .btn_s_c .ant-btn {
        border-radius: 2px !important;
        -webkit-border-radius: 2px !important;
        -moz-border-radius: 2px !important;
        -ms-border-radius: 2px !important;
        -o-border-radius: 2px !important;
        font-size: 14px;
        font-weight: 700;

        &.ant-btn-default {
            color: #9E9998 !important;
            border: 1px solid #9E9998 !important;
        }
    }
}

/* .inputContainer .i_i_c_b {
    border: none !important;
} */

.leftpanelContainer .booking-duration-container {
    max-width: 100% !important;
}

.datarangecontainer.ant-input {
    font-weight: 200 !important;
}

.nw_flex_center {
    display: flex;
    align-items: center;
}

@media only screen and (max-width:767px) {
    .datarangecontainer.ant-input {
        border-left: 1px solid #d9d9d9 !important;
        min-height: 50px;
    }

    .container_wrap .searchContainer {
        position: relative !important;
    }

    .inputContainer .i_i_c_b,
    .d_c_b_m_d,
    .inputContainer .slider-container,
    .leftpanelContainer .booking-duration-container {
        margin-left: auto !important;
        margin-right: auto !important;
    }

    .leftpanelContainer {
        /* text-align: center; */

        .d_c_b {
            margin-left: auto !important;
            margin-right: auto !important;
            width: 100%;
        }

        .btn_s_c {
            justify-content: center;
        }

        .mob_spcer {
            margin-bottom: 10px;
            display: block;
        }

    }




}