.basHeading {
    color: #414350;
    font-weight: 500;
    margin-bottom: 10px;

}

.rowData {
    display: flex;
    margin-left: -5px;
    margin-right: -5px;
}

.rowData .spacerLR {
    padding-left: 5px;
    padding-right: 5px;
}

.actionStatus {
    text-align: left;
    font-size: 14px;

    span {
        cursor: pointer;
    }
}

.centerData {
    text-align: left;
    padding-left: 15px;
}

.setPricepercent input {
    position: relative;
}

.rightbtnAlign {
    display: flex;
    justify-content: flex-end;
}