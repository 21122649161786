.cockpit_web {
    .header {
        display: flex;
        flex-wrap: wrap;
        height: auto;
        width: 100%;
        justify-content: space-between;
        padding-top: 20px;
    }

    .ant-menu {
        color: rgba(58, 59, 57, .8);
        text-transform: uppercase;
        font-family: Work Sans;
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;

    }

    .logo {
        width: 160px;
        margin: 0;
        background: rgba(255, 255, 255, 0.3);
        height: 90px;
        display: flex;

        a {
            display: block;
            background: url(../images/logo.png) no-repeat center;
            background-size: contain;
            width: 100%;

        }
    }

    .rightContainer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .ant-menu-horizontal {
            border-bottom: 0;
        }

        .login {
            height: auto;
            display: block;
            position: relative;
            padding-left: 20px;
            padding-right: 40px;
            text-transform: uppercase;
            font-weight: 600;

            &::before {
                content: "";
                position: absolute;
                width: 1px;
                height: 10px;
                background: rgba(58, 59, 57, .5);
                top: 50%;
                left: 0px;
                margin-top: -5px;
            }

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: auto;
                color: rgba(58, 59, 57, .8);

                span {
                    display: flex;
                    margin-right: 8px;
                }
            }
        }

        .ant-btn {
            color: rgba(58, 59, 57, 0.8);
            border: 1px solid rgba(58, 59, 57, 0.8);
            border-radius: 0px !important;
            -webkit-border-radius: 0px !important;
            -moz-border-radius: 0px !important;
            -ms-border-radius: 0px !important;
            -o-border-radius: 0px !important;
            box-shadow: none;
            text-transform: uppercase;
            font-weight: 500;
            padding-top: 10px;
            padding-bottom: 10px;
            height: auto;
            font-size: 1.4rem;
        }
    }
}