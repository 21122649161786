.--cockpit-calendar-view {
  .rbc-toolbar-label {
    font-weight: 500;
  }
  .rbc-month-view,
  .rbc-time-view,
  .rbc-agenda-table {
    background-color: #fff;
  }
  .rbc-btn-group {
    background-color: #fff;
    box-shadow: none;
    font-size: 14px;
    .rbc-active,
    .rbc-active:focus,
    .rbc-active:hover {
      background-color: black;
      color: #fff;
      box-shadow: none;
      border-color: black;
    }
    button {
      height: 32px;
      line-height: 2px;
      background-color: #fff;
      cursor: pointer;
    }
  }
}
