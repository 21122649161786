.ant-modal-content {
    border-radius: 4px;
}



.ant-modal-header .ant-modal-title {
    font-size: 20px;
    color: #333333;
}

.page-content {
    font-size: 16px;
}

.profile-container .page-content {
    padding: 30px;
}

.ant-select-arrow .anticon {
    svg {
        display: none;
    }

    &:before {
        content: "";
        background: url(../svg/drop-down.svg) no-repeat;
        width: 8px;
        height: 5px;
        background-position: center;
        display: block;
        margin-top: 2px;
    }
}

.btn-book-now-container button:hover {
    color: #FBFAFB !important;
    background-color: rgba(0, 0, 0, .5) !important;
    border: 1px solid rgba(0, 0, 0, .5) !important;
}

.nw_btn_common.ant-btn {
    height: auto;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 700;
    color: #9E9998 !important;
    border: 1px solid #9E9998 !important;
    border-radius: 2px;
}

.nw_btn_view.ant-btn {
    background: #FFFFFF !important;
    border: 1px solid #F3F3F3 !important;
    color: #000000 !important;
}



.nw_btn_white.ant-btn,
.ant-modal-footer .ant-btn-default {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 14px;
    font-weight: 700;
    color: #9E9998 !important;
    border: 1px solid #9E9998 !important;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}

.nw_btn_black.ant-btn,
.ant-modal-footer .ant-btn-primary {
    padding-left: 25px;
    padding-right: 25px;
    font-size: 14px;
    font-weight: 700;
    color: #FFFFFF !important;
    border: 1px solid #000 !important;
    background-color: #000 !important;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
}



.ant-modal-footer .ant-btn-default,
.ant-modal-footer .ant-btn-primary {
    height: 40px;
}


.ant-modal-footer .ant-btn-primary,
.btn_green.ant-btn {
    /* background-color: #1C823E !important;
    border: 1px solid #1C823E !important; */
    border: 1px solid #000 !important;
    background-color: #000 !important;
}

.table-heading {
    font-weight: 600;
    font-size: 20px;
    color: #333333;
}



/* @media(min-width:768px) { */
.mainHeader {
    height: 82px !important;
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    .container {
        width: 100%;
    }
}

.widget-stat {
    margin-bottom: 25px;
}

.widget-stat .ant-card-hoverable:hover,
.ant-card-hoverable .ant-card-hoverable:hover,
.nw_table_container:hover,
.cardHover .ant-card-hoverable:hover {
    box-shadow: 0 0px 5px rgba(0, 0, 0, .1);
}

.cardHover {
    .ant-card-hoverable {
        transition: none;
        border: 0px;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
    }
}



.nw_table_container {
    thead>tr>th {
        background: rgba(196, 196, 196, .2) !important;
        font-weight: 800;
        font-size: 14px;
        color: #333333;
    }

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: #D9D9D9;
    }
}

.btn-book-now-container {
    button {
        padding: 15px 38px;
        font-weight: 700;
        font-size: 14px;
        color: #fff;
        height: auto;
    }
}

.nw_report {
    padding-top: 10px;
}

.nw_filter_container {
    padding: 10px 0 !important;
    border-radius: 4px !important;

    .ant-card-head-title {
        padding-bottom: 0 !important;
    }

    .ant-card-head {
        border-bottom: 0px;
    }
}

/* } */

.credit-cards-group {

    .ant-radio-wrapper {
        opacity: .5;

        &.ant-radio-wrapper-checked {
            opacity: 1;

        }

        .ant-radio {
            display: none;
        }


    }

}

.stripe-card-pay-details-container {
    position: relative;

}

.ant-radio-wrapper-checked {

    .stripe-card-pay-details-container {
        &:before {
            content: "";
            position: absolute;
            top: 5px;
            right: 5px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
            background: green url(../svg/check.svg) no-repeat center;
            display: flex;
            align-items: center;
            justify-content: center;
            background-size: 40%;
        }
    }

}

@media only screen and (max-width:767px) {
    .ant-layout-content .m20.p10 {
        margin: 0;
        padding: 30px 15px 15px;
    }

    .mainHeader .container .user-info-container .user-avatar {
        background: #fff;
        color: #000;
    }

    .nw_heading {
        display: block;
        width: 100%;
    }

    .nw_table_container .ant-card-body {
        padding: 15px;
    }

    .nw_mt_spcer {
        margin-top: 30px;
    }

    .nw_mobile_container {
        width: 100% !important;
        margin: 0px !important;
        margin-bottom: 15px !important;
    }

    .ant-table table {
        /* width: 1200px !important; */
    }

    .rbc-toolbar {
        flex-direction: column;

        .rbc-toolbar-label {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .blank-card-container,
    .--card-layout {
        margin-right: auto !important;
        margin-left: auto !important;
    }

    .nw_center_container {
        text-align: center;
    }

    .nw_table_container_box {
        .ant-table-tbody>tr>td {
            white-space: nowrap;
        }
    }

    .spacerTBMain {}
}