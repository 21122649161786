.searchContainer {
    background: #F4F4F4;
    padding: 10px 0;
    margin-top: 30px;

    .rowContainer {
        margin-left: -10px;
        margin-right: -10px;
    }

    .spacerLR {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 0;
    }
}

.locationContainer {
    background: #fff;
    position: relative;
    max-width: 100%;
    padding-left: 44px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 100%;

    i {
        display: flex;
        justify-content: center;
        position: absolute;
        left: 10px;
        top: 50%;
        margin-top: -12px;
        width: 24px;
        height: 24px;

        img {
            max-width: 100%;
        }
    }

    .mainLocation {
        font-size: 10px;
        color: rgba(58, 59, 57, .5);
        position: relative;
        padding-top: 5px;
        display: flex;
        text-transform: uppercase;
    }

    .ant-select-selector {
        height: 100% !important;
        padding: 0px !important;
        display: flex !important;
        align-items: center;
        border: none !important;
        outline: none;
        background: transparent !important;


        .ant-select-selection-search {
            height: 100% !important;
            display: flex !important;
            align-items: center;
        }

        .ant-select-selection-search {
            left: 0px !important;
        }

        .ant-select-selection-item {
            color: #000;
        }
    }

    .ant-select-focused {
        .ant-select-selector {
            box-shadow: none !important;
        }
    }

    input {
        outline: none;
        background: transparent;
        border: none !important;
        font-weight: 700;
        padding-left: 0px !important;
        padding-right: 0px !important;
        text-transform: uppercase;
        color: #000;
    }

    .inputstyle {
        width: 100%;
        margin-top: -5px;
        font-weight: 700;

        &:disabled {
            background: transparent;
            color: #000;
        }
    }

    .inputstyle.ant-input:focus,
    .inputstyle.ant-input-focused {
        box-shadow: none !important;
    }

    .ant-select-selection-item {
        text-transform: uppercase;
    }
}

.btnContainer {
    position: relative;

    .ant-btn-primary {
        display: block;
        border-radius: 0px !important;
        -webkit-border-radius: 0px !important;
        -moz-border-radius: 0px !important;
        -ms-border-radius: 0px !important;
        -o-border-radius: 0px !important;
        height: 100%;
        text-transform: uppercase;
        font-weight: 700;
        text-shadow: none !important
    }
}

.datarangecontainer {
    .ant-picker-active-bar {
        margin-left: 0 !important;
    }

    .ant-picker-suffix {
        display: none !important;
    }
}

.arrowset {
    .ant-select-arrow {
        background: url(../images/triangle.svg) no-repeat center;

        span.anticon-down {
            display: none;
        }
    }
}

@media (max-width:1199px) {
    .spacerLR {
        margin-bottom: 20px !important;
    }
}

@media (max-width:767px) {
    .spacerLR {
        margin-bottom: 15px !important;
    }

    .btnContainer {
        height: 56px;
    }
}


/* ============================= */

.spacerTBMain {
    padding-top: 30px;
    padding-bottom: 80px;

}

.bannercontainer {
    padding-top: 40px;
    position: relative;
    display: flex;
    justify-content: center;

    img {
        max-width: 100%;
    }
}

.leftpanelContainer {
    position: relative;
    max-width: 600px;

    .li_c_b {
        position: relative;
        padding-top: 30px;
    }

    .d_c_b {
        display: flex;
        padding: 10px 15px;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #ccc;
        cursor: pointer;
        margin-bottom: 20px;
        /* max-width: 500px; */

        &.activeRadio {
            border: 1px solid #74BA59;

            i {
                background: url(../images/green_oval.svg) no-repeat center !important;
            }
        }

        .d_d_c_b {
            font-size: 16px;
            color: #3A3B39;
            position: relative;
            display: flex;
            align-items: center;


            i {
                display: flex;
                margin-right: 10px;
                margin-top: 2px;
                justify-items: center;
                width: 14px;
                height: 14px;
                background: url(../images/blank_oval.svg) no-repeat center;
                background-size: contain;

            }
        }
    }

    .btn_s_c {
        display: flex;
        padding-top: 30px;
        /* padding-left: 10px;
        padding-right: 10px; */

        .spacerdata {
            button:disabled {
                opacity: .5;
                color: #fff;
            }

            /* margin-left: -10px;
            margin-right: -10px; */
        }

        .ant-btn {
            border-radius: 0px !important;
            -webkit-border-radius: 0px !important;
            -moz-border-radius: 0px !important;
            -ms-border-radius: 0px !important;
            -o-border-radius: 0px !important;
            height: 100%;
            text-transform: uppercase;
            text-shadow: none !important;
            height: 56px;
            min-width: 160px;
            margin-right: 20px;
            box-shadow: none !important;

        }
    }

    .booking-duration-container {
        /* max-width: 350px !important; */
        margin-bottom: 25px;
    }

    .ant-segmented-item-selected {
        background-color: #74ba59 !important;
        color: #fff !important;
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    }

    .ant-segmented-item {
        transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    }

    .ant-segmented-thumb {
        background-color: #74ba59 !important;
    }

}

.ant-slider-track {
    background-color: #74ba59 !important;
}

.ant-slider-handle {
    border: solid 2px #74ba59 !important;
}

.slider-container {
    width: 80%;
    margin-top: 5vh;
}


.timeline_container {
    background: #F4F4F4;
    padding: 15px 0;
    width: 100%;
    position: fixed !important;
    left: 0px;
    bottom: 0px;


    .inner_t_c {
        width: 100%;
        text-align: center;

        ul {
            position: relative;
            display: inline-block;
            align-items: center;
            list-style: none;
            width: 100%;
            max-width: 900px;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                position: relative;
                width: 33.33%;

                &::after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background: #ccc;
                    position: absolute;
                    top: 50%
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }

                span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 56px;
                    height: 56px;
                    background: #fff;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    border: 1px solid #ccc;
                }

                i {
                    display: block;
                    width: 30px;
                    height: 30px;
                    background-repeat: no-repeat !important;
                    background-position: center !important;
                    background-size: contain !important;
                }

                &.f_li_c {
                    position: relative;

                    i {
                        background: url(../images/blank_space.svg);
                    }

                    &.active {
                        span {
                            border: 1px solid #74BA59;
                        }

                        i {
                            background: url(../images/green_space.svg);
                        }
                    }

                    &.active_data {
                        span {
                            border: 1px solid #74BA59;
                            background: #74BA59;
                        }

                        i {
                            background: url(../images/white_space.svg);
                        }

                        &::after {
                            height: 4px;
                            background: #74BA59;
                            margin-top: -2px;
                        }
                    }
                }

                &.s_li_c {
                    position: relative;

                    i {
                        background: url(../images/blank_office_desk.svg);
                    }

                    &.active {
                        span {
                            border: 1px solid #74BA59;
                        }

                        i {
                            background: url(../images/blank_office_desk.svg);
                        }
                    }

                    &.active_data {
                        span {
                            border: 1px solid #74BA59;
                            background: #74BA59;
                        }

                        i {
                            background: url(../images/white_office_desk.svg);
                        }

                        &::after {
                            height: 4px;
                            background: #74BA59;
                            margin-top: -2px;
                        }
                    }
                }

                &.t_li_c {
                    position: relative;

                    i {
                        background: url(../images/blank_calendar.svg);
                    }

                    &.active {
                        span {
                            border: 1px solid #74BA59;
                        }

                        i {
                            background: url(../images/green_calendar.svg);
                        }
                    }

                    &.active_data {
                        span {
                            border: 1px solid #74BA59;
                            background: #74BA59;
                        }

                        i {
                            background: url(../images/white_calendar.svg);
                        }

                        &::after {
                            height: 4px;
                            background: #74BA59;
                            margin-top: -2px;

                        }
                    }
                }
            }






        }
    }


}





.inputContainer {
    position: relative;

    .i_i_c_b {
        display: flex;
        /* max-width: 400px; */
        border: 1px solid #ccc;
        margin-bottom: 20px;

        .s_c_d_b {
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 50px;
            background: #E6E6E6;
            border-right: 1px solid #ccc;

            i {
                background: #E6E6E6 url(../images/blank_office_desk.svg) no-repeat center;
                width: 28px;
                height: 28px;
                background-size: contain;
            }

        }
    }

    &.data_s_c_b {
        .i_i_c_b {
            .s_c_d_b {
                i {
                    background: #E6E6E6 url(../images/blank_calendar.svg) no-repeat center;

                }

            }
        }
    }

}

.d_c_b_m_d {
    display: flex;
    max-width: 400px;
    flex-wrap: wrap;
    margin-left: -3px;
    margin-right: -3px;


    .d_b_cs {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 35px;
        height: 35px;
        font-size: 14px;
        background: #fff;
        border: 1px solid #ccc;
        color: rgba(0, 0, 0, 0.85);
        margin: 3px;
        cursor: pointer;
        border-radius: 0px;


        &.active {
            border: 1px solid #74BA59;
            background: #74BA59;
            color: #fff;
        }
    }

}


.data_c_c_b {
    input {
        text-align: center;
    }
}

.img_c_b {
    max-width: 60px;

    img {
        width: 100%;
    }
}


.containerCenter {
    text-align: center;
    padding: 70px 15px 15px;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lr_spacer {
    margin-left: -15px;
    margin-right: -15px;
    min-height: 400px;
}

.spacerLR_con {
    padding-top: 50px;
    padding-bottom: 25px;
}

.title_container {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.boxContainer {
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    -ms-border-radius: 4px !important;
    -o-border-radius: 4px !important;

    .ant-card-body {}
}

.lr_pad_spacing {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 30px;
}


.anttabledata {
    margin-bottom: 20px;
}

.anttabledata table {
    width: 100%;
    text-align: left;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
}



.anttabledata table tr th {
    position: relative;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    text-align: left;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background 0.3s ease;
}


.anttabledata table thead tr th {
    position: relative;
    padding: 16px 16px;
    overflow-wrap: break-word;
}


.anttabledata table thead tr th::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 1.6em;
    background-color: rgba(0, 0, 0, 0.06);
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
}

.anttabledata table thead tr th:last-child:before {
    display: none;
}

.anttabledata table td {
    position: relative;
    padding: 16px 16px;
    overflow-wrap: break-word;
    border-bottom: 1px solid #f0f0f0;
}


.ant-card .nw_c_cb {
    font-weight: 700 !important;
    font-size: 18px;
    color: #333333;
    margin-bottom: 5px;
}

.nw_cap_cb {
    font-size: 14px;
}

.nw_font_bold {
    font-weight: 700;
}

.nw_spacer_card {
    margin-left: -8px;
    margin-right: -8px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.nw_cb_m_box {
    margin-top: 30px;
}

@media(max-width:767px) {

    .container_wrap .searchContainer {
        padding: 0px;
        padding-bottom: 15px;
        background: #fbfafb;
    }

    .timeline_container {
        padding: 8px 0 8px;
    }

    .timeline_container .inner_t_c ul li span {
        width: 36px;
        height: 36px;
    }

    .timeline_container .inner_t_c ul li i {
        width: 18px;
        height: 18px;
    }

    .timeline_container .inner_t_c ul li.f_li_c.active_data::after {
        height: 2px;
        margin-top: -1px;
    }

    .mobremovebl.ant-input {
        border: none !important;
        min-height: 1px;
    }

    .locationContainer {
        border: 1px solid #ccc;
    }

    .searchContainer {
        /* .btnContainer {
    height: auto;
} */

        /*  .spacerLR {
     margin-bottom: 0 !important;
 } */
    }

    .leftpanelContainer .btn_s_c .ant-btn {
        margin-left: 5px;
        margin-right: 5px;
        min-width: 130px;
    }

    .nw_cb_m_box {
        margin-top: 0px;
    }

    .nw_c_mob_set {
        padding: 20px 15px !important;

        .nw_c_cb {
            margin-bottom: 15px;
        }
    }

    .nw_mob_space {
        margin-bottom: 15px !important;
    }

    .nw_mob_con_full {
        display: block !important;

        .nw_btn_black {
            margin-top: 15px;
        }
    }

    .lr_pad_spacing:last-child {
        margin-bottom: 0px;
    }

    .spacerTBMain {
        padding-top: 10px;
        padding-bottom: 30px;

    }
}