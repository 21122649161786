/* @import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;300;400;600;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;300;400;600;800;900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,500&family=Work+Sans:wght@100;300;400;600;800;900&display=swap');

*,
::after,
::before {
    box-sizing: border-box;
}

body,
html {
    width: 100% !important;
    color: rgba(58, 59, 57, .8);
    background: #fff;
    font-family: 'Work Sans', sans-serif;
    font-size: 10px;
    // font-weight: 500;
    overflow: auto;
}

.cockpit_web {
    background: #fff !important;

    .container {
        width: 100%;
        padding: 0 15px;
        margin-right: auto;
        margin-left: auto;
        position: relative;
    }

    @media (min-width: 576px) {
        .container {
            max-width: 540px;
        }
    }

    @media (min-width: 768px) {
        .container {
            max-width: 720px;
        }
    }

    @media (min-width: 992px) {
        .container {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 80%;
        }
    }
}



.popup_main {
    // width: 100%;
    // background: #f4f4f4;
    // position: absolute !important;
    // left: 0px;
    // top: 0px;
    // border-radius: 4px;
    // -webkit-border-radius: 4px;
    // -moz-border-radius: 4px;
    // -ms-border-radius: 4px;
    // -o-border-radius: 4px;
    // z-index: 3;
    // display: none;

    &.activepopup {
        display: block;
    }

    ::-webkit-scrollbar {
        background-color: transparent;
        width: 10px;
    }

    ::-webkit-scrollbar-button {
        display: none;
    }

    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #babac0;
        border-radius: 16px;
        border: 2px solid #fff;
    }

    ::-webkit-scrollbar-track {
        background-color: #fff;
    }

    .locationForm {
        .error {
            margin-top: -5px;
        }
    }

    .popup_inner {
        position: relative;
        height: 100%;
        padding: 25px;
    }

    .close_form {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        z-index: 1;
        cursor: pointer;
        background: none;
        border: 0;
        padding: 0;
        width: 28px;
        height: 28px;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 60%;
            height: 60%;
        }

    }

    .popup_heading {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    .popup_f_w {
        /* position: relative;
border: 1px solid #dddddd;
background-color: #fff;
border-radius: 5px;
position: relative;
height: 42px;
margin-bottom: 25px;
width: 100%; */



        &.popup_date {

            .ant-space-item {
                height: 100%;
            }
        }


        .ant-form-item-explain,
        .ant-form-item-extra {
            font-size: 12px;
            margin-top: 3px;
        }


    }

    .popup_field__input {
        display: flex;
        height: 100%;
        outline: none;
        border-color: transparent;
        border-radius: 5px;

        &.ant-form-item-has-error {
            input {
                border: 1px solid #ff4d4f;
            }
        }

        &.ant-form-item-has-error {
            .ant-select {
                border: 1px solid #ff4d4f;
            }
        }



        :hover {
            border-color: transparent;
        }

        input {
            font-family: inherit;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            font-size: 1.4rem;
            display: block;
            background-color: #fff;
            padding: 10px;
            outline: 0;
            border: 0;
            cursor: text;
            outline: none;
            border: 1px solid #dddddd;

            :focus {
                outline: none;
            }
        }

        .ant-select {
            display: flex;
            height: 42px;
            border: 1px solid #dddddd;
            border-radius: 5px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
        }

        .ant-picker {
            padding-top: 0;
            padding-left: 0px;
            padding-bottom: 0px;
            width: 100%;
            border-radius: 5px;

            input {
                border: none;
            }
        }

        .ant-select-selector {
            display: flex;
            height: 100% !important;
            align-items: center;
            border: none !important;
            border-radius: 5px !important;

            .ant-select-selection-search {
                display: flex;
                align-items: center;
            }
        }


        .ant-input-group-addon {
            border-radius: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
            -ms-border-radius: 0px;
            -o-border-radius: 0px;


        }

        &.boder_tl {
            .ant-input-group-addon {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
        }

    }

    .popup_submit_button {
        width: 100%;
        margin-bottom: 0px;

        button {
            font-size: 1.4rem;
            font-weight: 600;
            border: 1px solid #74BA58;
            border-radius: 5px;
            padding: 10px 10px;
            background: #74BA58;
            color: #fff;
            width: 100%;
            height: 42px;
        }
    }
}



@import 'nw_responsive';