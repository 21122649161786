
.invoice-box {
    max-width: 900px;
    margin: auto;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    line-height: 25px;
    font-size: 16px;
}

.invoice-box table {
    width: 100%;
    border-collapse: collapse;
    tbody > tr:nth-child(even) {
    background: #fff !important;
}

    tr.total>td{
        padding:5px 20px;

    }
    tr.total>td.bold{
        font-weight: 600 !important;
    }
    .borderBottom{
        border-bottom: 1px solid #000;
    }
    .borderBottomLight{
        border-bottom: 1px solid #c9c6c6;
    }
}


.invoice-box table td {
    padding: 15px;
    vertical-align: top;
}


.invoice-box table tr td:nth-child(2) {
    text-align: right;
    margin-top: 30px;
}

.invoice-box table tr.heading td {
    // background: #eee;
    // border-bottom: 1px solid #ddd;
    font-weight: bold;
    width: 100%;
    padding: 12px 20px;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    padding: 12px 20px;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

