table.customTable {
  width: 100%;
  border-collapse: collapse;
  border: 2px solid #eff2f7;
  margin-bottom: 1rem;
  color: #495057 !important;
}

table.customTable td {
  border: 2px solid #f2f2f2 !important;
  text-align: center;
  padding: 5px !important;

}

table.customTable th {
  border-width: 2px !important;
  border-color: #f2f2f2!important;
  border-style: solid !important;
  padding: 5px !important;
}
