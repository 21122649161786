.container_wrap {
    /* float: left; */
    width: 100%;
    padding: 0;

    .searchContainer {
        margin-top: 0px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .searchContainer+.container {
        padding-left: 15px;
        padding-right: 15px;

        .spacerTBMain {
            padding-top: 50px;
        }
    }

    /* .containerCenter {
    text-align: center;
    padding: 70px 15px 15px;
    min-height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
} */
}