.nw_heading {
  display: flex;
  flex-flow: row wrap;
  color: #333333;
  font-weight: 600;
  font-size: 20px;
  margin: 0px;
  line-height: normal;
}

.nw_align_center {
  align-items: center;
}

.nw_para {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
  margin: 0;
}

.nw_30 {
  margin-bottom: 30px;
}

.nw_spacer_aside {
  flex: 0 0 300px;
  max-width: 300px;
  width: 100%;
}

.nw_rp_spacer {
  padding-left: 16px;
}

.nw_card {
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: 20px;
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
}

.widget-stat {
  margin-bottom: 25px;

  .ant-card-hoverable:hover {
    box-shadow: 0 0px 5px rgba(0, 0, 0, .03);
  }

  .ant-card-body {
    padding: 15px 20px;

    .card-heading {
      font-size: 16px;
      font-weight: 700;
    }

    .card-value {
      font-size: 14px;
      color: #9E9998;
      font-weight: 700;
    }
  }

  .media {
    display: flex;
    padding: 0;
    align-items: center;
  }

  .bgl-primary {
    height: 85px;
    width: 85px;
    border-radius: 50px;
    padding: 10px 12px;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464a53;
    // margin-right: 15px;
    background: #f4f6fd;
    border-color: #f4f6fd;
    color: #2f4cdd;

    &.bg_1 {
      background: rgba(47, 76, 221, 0.2);
      border-color: rgba(47, 76, 221, 0.2);
      color: rgba(47, 76, 221, 1);
    }

    &.bg_2 {
      background: rgba(36, 172, 71, 0.2);
      border-color: rgba(36, 172, 71, 0.2);
      color: rgba(36, 172, 71, 1);
    }

    &.bg_3 {
      background: rgba(255, 92, 90, 0.2);
      border-color: rgba(255, 92, 90, 0.2);
      color: rgba(255, 92, 90, 1);
    }

    &.bg_4 {
      background: rgba(181, 25, 236, 0.2);
      border-color: rgba(181, 25, 236, 0.2);
      color: rgba(181, 25, 236, 1);
    }

    &.bg_5 {
      background: rgba(58, 34, 114, 0.2);
      border-color: rgba(58, 34, 114, 0.2);
      color: rgba(58, 34, 114, 1);
    }

    &.bg_6 {
      background: rgba(0, 204, 204, 0.2);
      border-color: rgba(0, 204, 204, 0.2);
      color: rgba(0, 204, 204, 1);
    }

    &.bg_7 {
      background: rgba(94, 169, 221, 0.2);
      border-color: rgba(94, 169, 221, 0.2);
      color: rgba(94, 169, 221, 1);
    }
  }

  .media-body {
    flex: 1;
  }

  .media-body h3 {
    font-size: 46px;
    font-weight: 700;
    margin: 0;
    line-height: 1.2;
  }

  .media-body p {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }

  .mediaBodycon p {
    cursor: pointer;
    color: #74ba58;
  }
}

.pt_20 {
  padding: 20px;
}

.nw_gridWhite {
  background: #fff;

  .nw_h3 {
    font-size: 1.8rem;
    color: #000;
    margin-bottom: 0px;
    padding-bottom: 15px;
    border-bottom: 1px solid #f0f0f0;
    font-weight: 500;
  }
}

.nw_s_25 {
  margin-bottom: 25px;
}

.nk-tb-list {
  display: table;
  width: 100%;
  font-size: 14px;
  color: #8094ae;
}

.nk-tb-col {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 10px 20px;
}

.nk-tb-item {
  transition: background-color 0.3s, box-shadow 0.3s;
  display: table-row;
}

.nk-tb-head .nk-tb-col {
  padding-top: 15px;
  padding-bottom: 15px;
  /*  color: #8094ae; */
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 1.4rem;
  border-bottom: 1px solid #f0f0f0;
}

.nk-tb-item:not(:last-child) .nk-tb-col {
  border-bottom: 1px solid #f0f0f0;
}

.nk-tb-list .tb-lead,
.nk-tb-list .tb-amount {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  line-height: 1.4;
}

.nw_orderId {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  font-style: normal;
}

.user-card {
  display: flex;
  align-items: center;
}

.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #1890ff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.06em;
  flex-shrink: 0;
  position: relative;

  &.bg_1 {
    background: rgba(47, 76, 221, 1);
  }

  &.bg_2 {
    background: rgba(36, 172, 71, 1);
  }

  &.bg_3 {
    background: rgba(255, 92, 90, 1);
  }

  &.bg_4 {
    background: rgba(181, 25, 236, 1);
  }

  &.bg_5 {
    background: rgba(58, 34, 114, 1);
  }

  &.bg_6 {
    background: rgba(0, 204, 204, 1);
  }

  &.bg_7 {
    background: rgba(94, 169, 221, 1);
  }
}

.color_1 {
  color: rgba(47, 76, 221, 1);
}

.color_2 {
  color: rgba(36, 172, 71, 1);
}

.color_3 {
  color: rgba(255, 92, 90, 1);
}

.color_4 {
  color: rgba(181, 25, 236, 1);
}

.color_5 {
  color: rgba(58, 34, 114, 1);
}

.color_6 {
  color: rgba(0, 204, 204, 1);
}

.color_7 {
  color: rgba(94, 169, 221, 1);
}

.user-avatar-sm,
.user-avatar.sm {
  height: 32px;
  width: 32px;
  font-size: 12px;
}

.user-avatar+.user-name,
[class^="user-avatar"]:not([class*="-group"])+.user-name {
  margin-left: 0.75rem;
}

.nk-tb-list .tb-sub,
.nk-tb-list .tb-amount-sm {
  font-size: 0.9em;
}

.nk-tb-list .tb-amount span,
.nk-tb-list .tb-amount .currency {
  color: #526484;
  font-weight: 400;
}

.badge-dot {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding-left: 12px;
  padding-right: 0;
  font-size: 12px;
}

.badge-dot:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  height: 6px;
  width: 6px;
  margin-top: 0px;
  border-radius: 50%;
  background: currentColor;
  left: 15px;
}

.tb-col-sm {
  max-width: 200px;
}

.badge-dot.badge-success {
  color: #1ee0ac;
}

.badge-dot.badge-danger {
  color: #e85347;
}

.badge-dot.badge-warning {
  color: #f4bd0e;
}

.nk-tb-item:not(.nk-tb-head):hover,
.nk-tb-item:not(.nk-tb-head).seleted,
.nk-tb-head {
  /* background: #f8f9fc;
    box-shadow: 0 2px 15px -4px rgba(133, 79, 255, .4); */
  background: #fafafa;
}

/* .nk-tb-head {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
} */

.nk-tb-col .ant-btn-primary {
  background: #1890ff !important;
  border-color: #1890ff !important;
}

.actiondetail {
  color: rgba(0, 0, 0, 0.85);
  padding-left: 50px;
}

.profilegridBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.porfilenamebox {
  position: relative !important;
  display: flex;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding-left: 15px;

  .nameBox {
    position: relative;
    width: 100%;
  }

  .h_title {
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
  }
}

.pos_content {
  position: absolute;
  right: 0px;
  top: 6px;
}

.spacerDetail {
  padding: 24px;
}

.table-heading {
  font-weight: 600;
}

@import "new-style";