.cms-card {
  width: 300px !important;
  margin: 10px 20px 10px 0 !important;
  border-radius: 5px !important;
}

.cms-modal {
  width: 700px !important;

  .ant-modal-body {
    padding: 0;
  }

  .content {
    max-height: 75vh;
    padding: 15px;
    overflow-y: auto;
  }

  /* Extra small devices (phones, 576px and down) */
  @media only screen and (max-width: 576px) {
  }

  /* Small devices (portrait tablets and large phones, 576px and up) */
  @media only screen and (min-width: 576px) {
    .content {
      .custom-upload-list {
        .custom-upload-image,
        .ant-upload-list-picture-container {
          width: 450px;
        }
      }
    }
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 750px !important;

    .content {
      .custom-upload-list {
        .custom-upload-image,
        .ant-upload-list-picture-container {
          width: 350px;
        }
      }
    }
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 900px !important;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
}
