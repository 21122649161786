.pay-now-container {

  .members-container,
  .selected-resource-container,
  .booking-details-container {
    .ant-card-body {
      padding: 10px 15px;
    }
  }

  .label {
    color: #333333;
    font-weight: 400;
  }

  .value {
    font-weight: 700;
  }

  .ant-typography {
    margin: 0;
  }

  .booking-details-container {
    .value {
      text-align: right;
    }
  }

  .workspace-details-container {
    .workspace-name {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .time-to,
  .price-unit {
    color: #9e9998;
    font-weight: 400;
  }

  .total-price-container {
    font-size: 24px;

    .label {
      font-weight: 700;
    }
  }

  @media (max-width: 576px) {
    .--left-container {
      padding-right: 0;

      .selected-resource-container {
        .resource-image-container {
          /* max-height: 120px; */
        }
      }

      .resource-details-container {
        margin-top: 20px;
        padding: 0;
      }
    }
  }

  @media (min-width: 576px) {
    .--left-container {
      padding-right: 0;
    }
  }

  @media (min-width: 768px) {
    .--left-container {
      padding-right: 20px;
    }
  }

  //   @media (min-width: 992px) {
  //     .--left-container {
  //       padding-right: 20px;
  //     }
  //   }
}